.login-page {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    33deg,
    rgba(2, 0, 36, 1) 31%,
    rgba(0, 212, 255, 1) 51%,
    rgba(7, 21, 36, 1) 75%
  );
  background-image: url("/dist/img/bg_1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}
.login-box {
  width: 460px;
}
.login-logo {
  font-size: 1.9rem;
  color: rgba(225, 225, 225, 0.5);
}
.login-logo a {
  color: #fff;
}
.login-logo i {
  font-size: 80%;
}
.login-card-body {
  background-color: rgba(0, 0, 0, 0) !important;
  /* background-color: red !important; */
  border: 0 solid rgba(0, 0, 0, 0) !important;
  box-shadow: 0 0 0 0;
}
.login-card-body a {
  color: #e4c334;
  /* color: #f0ff10; */
}
.card {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0 solid rgba(0, 0, 0, 0) !important;
  /* background-color: red !important; */
}
.login-card-body .input-group .input-group-text {
  background-color: #fff;
}
.login-box-msg {
  color: #fff;
  font-size: 1.1rem;
}
.login-page .invalid-feedback {
  color: #fff;
}
